<template>
    <div class="kwast-table d-flex flex-row flex-wrap justify-content-between">
        <slot name="items">
        </slot>
    </div>
</template>

<script type="ts">
export default {
    props: {
    },
    setup() {
        return {
            //
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";

.kwast-table {
    &:after {
        content: '';
        width: 300px;
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
}

@media only screen and (max-width: 1400px) {
    .kwast-table {
        justify-content: space-evenly !important;
        &:after {
            content: '';
            width: 300px;
        }
    }
}
</style>
