import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZaltoCard = _resolveComponent("ZaltoCard")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "text-start mb-4" }, " Zalto glas ", -1)),
    ($setup.items.length > 0)
      ? (_openBlock(), _createBlock(_component_Table, { key: 0 }, {
          items: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.items, (zalto) => {
              return (_openBlock(), _createBlock(_component_ZaltoCard, {
                key: zalto.id,
                zalto: zalto,
                onWineClick: ($event: any) => ($setup.openZalto(zalto))
              }, null, 8, ["zalto", "onWineClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}