import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tooltip bs-tooltip-bottom", {'show': $props.showTooltip}]),
    style: _normalizeStyle($props.tooltipStyle),
    role: "tooltip"
  }, [
    _createElementVNode("div", {
      class: "tooltip-arrow",
      style: _normalizeStyle($props.arrowStyle)
    }, null, 4),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"tooltip-inner\" data-v-1c5f793e><ul class=\"list-unstyled\" data-v-1c5f793e><li class=\"text-start\" data-v-1c5f793e><div class=\"circle green me-2\" data-v-1c5f793e></div>Product in vrije voorraad </li><li class=\"text-start\" data-v-1c5f793e><div class=\"circle orange me-2\" data-v-1c5f793e></div>Product niet in vrije voorraad, staat in bestelling </li><li class=\"text-start\" data-v-1c5f793e><div class=\"circle red me-2\" data-v-1c5f793e></div> Product niet in vrije voorraad, staat niet in bestelling </li></ul></div>", 1))
  ], 6))
}