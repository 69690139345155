import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button d-flex flex-row" }
const _hoisted_2 = { class: "display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemoveIcon = _resolveComponent("RemoveIcon")!
  const _component_AddIcon = _resolveComponent("AddIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "minus",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.minus && $setup.minus(...args)))
    }, [
      _createVNode(_component_RemoveIcon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.counterFormatted) = $event)),
        type: "number",
        readonly: ""
      }, null, 512), [
        [_vModelText, $setup.counterFormatted]
      ])
    ]),
    _createElementVNode("button", {
      class: "plus",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.plus && $setup.plus(...args)))
    }, [
      _createVNode(_component_AddIcon)
    ])
  ]))
}