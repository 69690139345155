
import { computed, ref, watch } from 'vue';
import AddIcon from '@/assets/icons/add.svg';
import RemoveIcon from '@/assets/icons/remove.svg';

export default {
    props: {
        modelValue: {
            default: 0,
            type: Number,
            required: true,
        },
    },
    components: {
        AddIcon,
        RemoveIcon,
    },
    setup(props: any, context: any) {
        const counter = ref(props.modelValue);
        watch(props, () => {
            counter.value = props.modelValue;
        });

        const counterFormatted = computed(() => counter.value.toLocaleString('nl-NL', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        }));

        function minus() {
            if (counter.value !== 0) {
                counter.value -= 1;
                context.emit('update', counter.value);
            }

            context.emit('minus', -1);
        }

        function plus() {
            counter.value += 1;
            context.emit('update', counter.value);
            context.emit('plus', 1);
            counter.value = props.modelValue;
        }

        return {
            plus,
            minus,
            counterFormatted,
        };
    },
};
