
import { plainToClass } from 'class-transformer';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/helpers/Debounce';
import CounterButton from '@/shared/components/CounterButton.vue';
import Tooltip from '@/shared/components/Tooltip.vue';
import { Wine } from '@/shared/models/Wine';
import { Zalto } from '@/shared/models/Zalto';

export default {
    components: { Tooltip, CounterButton },
    props: {
        zalto: {
            type: Zalto,
            required: true,
        },
    },
    setup(props: any) {
        const store = useStore();
        const zaltoRef = ref(props.zalto);
        const quantity = computed(() => Math.floor(zaltoRef.value.quantity / zaltoRef.value.units_per_parcel));
        const debouncer = debounce(() => {
            store.dispatch('CART_UPDATE', { item_no: String(zaltoRef.value.no), quantity: zaltoRef.value.quantity });
        }, 1000);

        function favorite() {
            zaltoRef.value.is_favorited = !zaltoRef.value.is_favorited;

            if (zaltoRef.value.wine) {
                zaltoRef.value.wine.is_favorited = zaltoRef.value.is_favorited;
                store.dispatch('WINE_FAVORITE', zaltoRef.value.wine);
                return;
            }

            store.dispatch('WINE_FAVORITE', zaltoRef.value).then((res) => {
                zaltoRef.value = plainToClass(Wine, res.data.data);
            });
        }

        function addToCart(amount: any) {
            const dataLayer = window.dataLayer || [];
            let eventType = 'add_to_cart';
            if (amount < 0) {
                eventType = 'remove_from_cart';
            }

            dataLayer.push({
                ecommerce: null,
            });

            dataLayer.push({
                event: eventType,
                ecommerce: {
                    currencyCode: 'EUR',
                    value: zaltoRef.value.price,
                    items: [
                        {
                            item_id: zaltoRef.value.no,
                            item_name: zaltoRef.value.name,
                            index: 0,
                            item_brand: zaltoRef.value.color,
                            item_category: zaltoRef.value.country.name,
                            item_category2: zaltoRef.value.region.name,
                            item_category3: zaltoRef.value.estate.name,
                            item_category4: `${zaltoRef.value.alcohol_percentage}%`,
                            item_category5: zaltoRef.value.grapes.map((grape) => grape.name).join(', '),
                            price: zaltoRef.value.price,
                            quantity: amount * zaltoRef.value.units_per_parcel,
                        },
                    ],
                },
            });
            zaltoRef.value.quantity += amount * zaltoRef.value.units_per_parcel;
            debouncer();
        }

        function truncate(string: string) {
            const max = 47;
            return string.length > max ? `${string.slice(0, max - 1)}…` : string;
        }

        return {
            favorite,
            zaltoRef,
            truncate,
            addToCart,
            quantity,
            showTooltip: ref(false),
        };
    },
};
