
import { plainToClass } from 'class-transformer';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ZaltoCard from '@/components/ZaltoCard.vue';
import Table from '@/shared/components/Table.vue';
import { Zalto } from '@/shared/models/Zalto';

export default {
    components: {
        ZaltoCard,
        Table,
    },
    setup() {
        const items = ref(Array<Zalto>());
        const store = useStore();

        function getZalto() {
            store.dispatch('ZALTO_GET', { currentPage: 1 }).then((res) => {
                items.value = plainToClass(Zalto, res.data.data as Zalto[]);
            });
        }

        getZalto();

        const router = useRouter();

        function openZalto(zalto: Zalto) {
            const id = String(zalto.id);
            router.push({ name: 'ZaltoDetail', params: { id } });
        }

        return {
            openZalto,
            items,
        };
    },
};
