
export default {
    props: {
        showTooltip: {
            type: Boolean,
            default: false,
        },
        tooltipStyle: {
            type: String,
        },
        arrowStyle: {
            type: String,
        },
    },
    setup() {
        return {
        };
    },
};
