export class Wine {
    available_inventory!: number;
    bio!: boolean;
    brochure!: string;
    color!: string;
    country?: Relation;
    estate?: Relation;
    region?: Relation;
    grapes?: Array<Relation>;
    quantity!: number | 0;
    id!: number;
    no!: string;
    image_small?: string;
    image_medium?: string;
    image_large?: string;
    inventory!: number;
    inventory_color!: 'orange' | 'red' | 'green';
    is_favorited!: boolean;
    items_count?: number | 0;
    name!: string;
    price!: number;
    units_per_parcel!: number;
    type!: 'wine' | 'stopper' | 'zalto' | 'Revenue';
    vegan!: boolean;
    volume!: number;
    year_of_harvest!: string;
    wine?: Relation;
}

export class Relation {
    id!: number;
    name!: string;
}
