import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "kwast-display-card d-flex my-2 mx-2" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "info-box d-flex flex-column justify-content-between position-relative" }
const _hoisted_4 = { class: "top" }
const _hoisted_5 = { class: "bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_CounterButton = _resolveComponent("CounterButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "image-box d-flex align-items-center justify-content-center",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('wineClick')))
    }, [
      _createElementVNode("img", {
        src: $setup.zaltoRef.image_small,
        alt: "wine"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", {
          class: "text-start clickable mt-4",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('wineClick')))
        }, _toDisplayString($setup.truncate($setup.zaltoRef.name)), 1),
        _createElementVNode("p", {
          class: "text-start",
          style: {"cursor":"pointer"},
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('wineClick')))
        }, [
          _createTextVNode(" €" + _toDisplayString(String($setup.zaltoRef.price?.toFixed(2)).replace('.', ',')) + " ", 1),
          _createElementVNode("span", {
            class: _normalizeClass(["circle", $setup.zaltoRef.inventory_color]),
            onMouseenter: _cache[2] || (_cache[2] = ($event: any) => ($setup.showTooltip = true)),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => ($setup.showTooltip = false))
          }, null, 34),
          _createVNode(_component_Tooltip, { showTooltip: $setup.showTooltip }, null, 8, ["showTooltip"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-start fst-italic light" }, " 6 per doos ", -1)),
        _createVNode(_component_CounterButton, {
          modelValue: $setup.quantity,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.quantity) = $event)),
          onMinus: _cache[6] || (_cache[6] = ($event: any) => ($setup.addToCart($event))),
          onPlus: _cache[7] || (_cache[7] = ($event: any) => ($setup.addToCart($event)))
        }, null, 8, ["modelValue"]),
        _createElementVNode("p", {
          class: _normalizeClass(["format text-start text-decoration-underline", { 'opacity-0' : !$setup.zaltoRef.items_count || $setup.zaltoRef.items_count <= 1}]),
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('wineClick')))
        }, " Bestel in andere hoeveelheid ", 2)
      ])
    ])
  ]))
}